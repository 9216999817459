import { expectNonemptyString } from "../../../utils/expect";

export interface SmallBusiness {
  name: string;
  taxIdentificationNumber: string;
  taxOffice: string;
}

export function loadSmallBusiness(
  data: Record<string, unknown>,
): SmallBusiness {
  return {
    name: expectNonemptyString(data.name),
    taxIdentificationNumber: expectNonemptyString(data.taxIdentificationNumber),
    taxOffice: expectNonemptyString(data.taxOffice),
  };
}
