import {
  expectEnum,
  expectEnumOrNull,
  expectNever,
} from "../../../utils/expect";

import { Title } from "./title";

export const genders = ["male", "female", "unspecified"] as const;
export type Gender = (typeof genders)[number];

const titleGenders: { [t in Title]: Gender } = {
  mr: "male",
  ms: "female",
  mrdr: "male",
  msdr: "female",
  mrprof: "male",
  msprof: "female",
  avoidTitle: "unspecified",
};

export function expectGenderOrNull(data: unknown): Gender | null {
  return expectEnumOrNull(data, genders);
}

export function expectGender(data: unknown): Gender {
  return expectEnum(data, genders);
}

export function gender(title: Title | "" | undefined): Gender {
  if (title === undefined || title === "") {
    return "unspecified";
  }
  const g = titleGenders[title];
  // intentional double-check
  if ((g as any) === undefined) {
    throw new Error(`bad title: ${title}`);
  }
  return g;
}

export function genderText(title: Title | "" | undefined): string {
  if (title === undefined || title === "") {
    return "";
  }
  const genderValue = gender(title);
  switch (genderValue) {
    case "female":
      return "Frau";
    case "male":
      return "Herr";
    case "unspecified":
      return "";
    default:
      throw expectNever(genderValue);
  }
}
