import { EventInput } from "@fullcalendar/core";

import { AlgoliaSearchable } from "../../utils/algolia";
import {
  expectBoolean,
  expectDate,
  expectEnum,
  expectNever,
  expectNonemptyString,
  expectString,
} from "../../utils/expect";

export type BaseEvent = Pick<
  EventInput,
  "title" | "description" | "textColor" | "allDay" | "start" | "end"
> & { id: string; agencyID: string };

export function loadBaseEvent(data: Record<string, unknown>): BaseEvent {
  return {
    id: expectNonemptyString(data.id),
    agencyID: expectNonemptyString(data.agencyID),
    title: expectString(data.title),
    description: expectString(data.description),
    textColor: expectString(data.textColor),
    allDay: expectBoolean(data.allDay),
    start: expectDate(data.start),
    end: expectDate(data.end),
  };
}

export const eventTypes = [
  "project",
  "person-private",
  "person-project",
] as const;
export type EventType = (typeof eventTypes)[number];
export type IEvent = ProjectEvent | PersonProjectEvent | PersonPrivateEvent;

export type AllowedEvent =
  | AllowedProjectEvent
  | AllowedClientProjectEvent
  | AllowedClientPrivateEvent;

export function loadEvent(data: Record<string, unknown>): IEvent {
  const type = expectEnum(data.type, eventTypes);
  switch (type) {
    case "project":
      return loadProjectEvent(data);
    case "person-private":
      return loadPersonPrivateEvent(data);
    case "person-project":
      return loadPersonProjectEvent(data);
    default:
      return expectNever(type);
  }
}

export type EventSearchable = AlgoliaSearchable<
  Pick<IEvent, "title" | "description" | "type">
>;

export function loadEventSearchable(
  data: Record<string, unknown>,
): EventSearchable {
  return {
    objectID: expectNonemptyString(data.objectID),
    type: expectEnum(data.type, eventTypes),
    title: expectString(data.title),
    description: expectString(data.description),
  };
}

// Events of a project, not bound to persons
export interface ProjectEvent extends BaseEvent {
  type: "project";
  projectID: string;
}

export type AllowedProjectEvent = {
  type: "project";
  required: {
    projectID: string;
  };
};

export function loadProjectEvent(data: Record<string, unknown>): ProjectEvent {
  const type = data.type;

  if (type !== "project") {
    throw new Error("unreachable");
  }

  return {
    type,
    ...loadBaseEvent(data),
    projectID: expectNonemptyString(data.projectID),
  };
}

// Events of a person regarding a project
export interface PersonProjectEvent extends BaseEvent {
  type: "person-project";
  personID: string;
  projectID: string;
}

export type AllowedClientProjectEvent = {
  type: "person-project";
  required: {
    personID: string;
    projectID: string;
  };
};

export function loadPersonProjectEvent(
  data: Record<string, unknown>,
): PersonProjectEvent {
  const type = data.type;

  if (type !== "person-project") {
    throw new Error("unreachable");
  }

  return {
    type,
    ...loadBaseEvent(data),
    personID: expectNonemptyString(data.personID),
    projectID: expectNonemptyString(data.projectID),
  };
}

// Private Events of a person
export interface PersonPrivateEvent extends BaseEvent {
  type: "person-private";
  personID: string;
}
export type AllowedClientPrivateEvent = {
  type: "person-private";
  required: {
    personID: string;
  };
};
export function loadPersonPrivateEvent(
  data: Record<string, unknown>,
): PersonPrivateEvent {
  const type = data.type;

  if (type !== "person-private") {
    throw new Error("unreachable");
  }

  return {
    type,
    ...loadBaseEvent(data),
    personID: expectNonemptyString(data.personID),
  };
}

export function eventTypeName(eventType: EventType): string {
  switch (eventType) {
    case "project":
      return "Projekt-Event";
    case "person-private":
      return "Privates Event";
    case "person-project":
      return "Rollen-Event";
    default:
      return expectNever(eventType);
  }
}

export const eventTypeNameOrBase = (types: EventType[]): string => {
  if (types.length === 1) {
    return eventTypeName(types[0]);
  }

  return "Event";
};
