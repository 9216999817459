import { LabelColor } from "../../frontend/components/Label";
import { AlgoliaSearchable } from "../../utils/algolia";
import {
  expectEnum,
  expectEnumOrNull,
  expectNever,
  expectNonemptyString,
  expectString,
} from "../../utils/expect";

export interface ProjectRole {
  id: string;
  agencyID: string;
  projectID: string;
  personID: string;
  name: string;
  description: string;
  type: ProjectRoleType | null;
  status: ProjectRoleStatus;
}

export function loadProjectRole(data: Record<string, unknown>): ProjectRole {
  return {
    id: expectNonemptyString(data.id),
    agencyID: expectNonemptyString(data.agencyID),
    projectID: expectNonemptyString(data.projectID),
    personID: expectNonemptyString(data.personID),
    name: expectString(data.name),
    description: expectString(data.description),
    type: expectEnumOrNull(data.type, projectRoleTypes),
    status: expectEnum(data.status, projectRoleStatusTypes),
  };
}

export type ProjectRoleSearchable = AlgoliaSearchable<
  Pick<ProjectRole, "name" | "status">
>;

export function loadProjectRoleSearchable(
  data: Record<string, unknown>,
): ProjectRoleSearchable {
  return {
    objectID: expectNonemptyString(data.objectID),
    name: expectNonemptyString(data.name),
    status: expectEnum(data.status, projectRoleStatusTypes),
  };
}

export const projectRoleTypes = [
  "lead",
  "supporting-role",
  "continuous-role",
  "recurring-role",
  "guest-appearance",
] as const;

export type ProjectRoleType = (typeof projectRoleTypes)[number];

export function projectRoleTypeLabel(rawStatus: string): string {
  const status = expectEnum(rawStatus, projectRoleTypes);

  switch (status) {
    case "lead":
      return "Hauptrolle";
    case "supporting-role":
      return "Nebenrolle";
    case "continuous-role":
      return "Durchgehende Rolle";
    case "recurring-role":
      return "Wiederkehrende Rolle";
    case "guest-appearance":
      return "Gastauftritt";
    default:
      return expectNever(status);
  }
}

export const projectRoleStatusTypes = [
  "requested",
  "casting",
  "active",
  "rejected",
  "archived",
] as const;

export type ProjectRoleStatus = (typeof projectRoleStatusTypes)[number];

export function projectRoleStatusLabel(rawStatus: string): string {
  const status = expectEnum(rawStatus, projectRoleStatusTypes);

  switch (status) {
    case "requested":
      return "Anfrage";
    case "casting":
      return "Casting";
    case "active":
      return "Aktiv";
    case "rejected":
      return "Abgelehnt";
    case "archived":
      return "Archiviert";
    default:
      return expectNever(status);
  }
}

export function projectRoleStatusColor(rawStatus: string): LabelColor {
  const status = expectEnum(rawStatus, projectRoleStatusTypes);

  switch (status) {
    case "requested":
    case "casting":
      return "warning";
    case "active":
      return "success";
    case "rejected":
      return "error";
    case "archived":
      return "default";
    default:
      return expectNever(status);
  }
}
