import { AlgoliaSearchable } from "../../utils/algolia";
import {
  expectArray,
  expectBoolean,
  expectDateOrNull,
  expectEnum,
  expectEnumOrNull,
  expectNonemptyString,
  expectNonemptyStringOrNull,
  expectNumber,
  expectString,
  loadArrayOfObjects,
  loadObject,
  loadObjectOrNull,
} from "../../utils/expect";

import { displayName, UserID } from "./auth";
import { Address, loadAddress } from "./person-helpers/address";
import { BahnCard, loadBahnCard } from "./person-helpers/bahn-card";
import { BankAccount, loadBankAccount } from "./person-helpers/bank-account";
import { BonusMile, loadBonusMile } from "./person-helpers/bonus-mile";
import {
  DimensionTable,
  loadDimensionTable,
} from "./person-helpers/dimension-table";
import {
  EducationDegree,
  loadEducationDegree,
} from "./person-helpers/educationDegree";
import { expectGenderOrNull, Gender } from "./person-helpers/gender";
import {
  HealthInsurance,
  loadHealthInsurance,
} from "./person-helpers/health-insurance";
import {
  MaritalStatus,
  maritalStatusTypes,
} from "./person-helpers/marital-status";
import { Nourishment } from "./person-helpers/nourishment";
import { expectPhone, Phone } from "./person-helpers/phone";
import { expectProfession, Profession } from "./person-helpers/profession";
import { Relation, relationTypes } from "./person-helpers/relation";
import {
  loadSmallBusiness,
  SmallBusiness,
} from "./person-helpers/small-business";
import {
  loadPersonSocialMedia,
  PersonSocialMedia,
} from "./person-helpers/social-media";
import { expectTitleOrNull, Title } from "./person-helpers/title";
import {
  loadVehicleLicense,
  VehicleLicense,
} from "./person-helpers/vehicle-license";

export interface Person extends NameInformation {
  id: string;
  agencyID: string;
  clientOf: UserID[];
  userNotes: UserNote[];

  birthday: Date | null;
  gender: Gender | null;

  address: Address;
  additionalAddresses: { name: string; address: Address }[];

  email: string | null;
  additionalEmails: string[];

  companyID: string | null;
  professions: Profession[];

  socialMedia: PersonSocialMedia;

  phone: Phone | null;
  additionalPhones: Phone[];

  stageName: NameInformation | null;

  birthName: NameInformation | null;

  placeOfBirth: string | null;
  countryOfBirth: string | null;
  citizenships: string[];
  maritalStatus: MaritalStatus | null;
  identityCardNumber: string | null;

  relatives: { relation: Relation; personID: string }[];
  religion: string | null;

  bankAccount: BankAccount | null;

  healthInsurance: HealthInsurance | null;

  smallBusiness: SmallBusiness | null;

  // Sozialversicherungsnummer
  socialInsuranceNumber: string | null;
  // Rentenversicherungsnummer
  pensionInsuranceNumber: string | null;
  taxIdentificationNumber: string | null;
  taxClass: string | null;
  taxOffice: string | null;
  // Kinder, die bei der Steuer angegeben werden.
  childrenTaxIDs: string[];
  // UID (Deutschland) / ATU (Österreich), Umsatzsteuer
  valueAddedTaxNumber: string | null;

  // Künstler-Sozial-Kasse (KSK)
  artistSocialFund: string | null;
  // Pensionskasse
  pensionFundNumber: string | null;
  pensionFundPercentage: PensionFundPercentage | null;
  memberOfGVL: boolean | null;

  bahnCard: BahnCard | null;
  bonusMiles: BonusMile[];

  nourishments: Nourishment[];
  allergies: string[];

  vehicleLicenses: VehicleLicense[];

  education: EducationDegree[];
  languages: string[];
  dialects: string[];
  sports: string[];
  dances: string[];
  instruments: string[];
  vocalStyles: string[];
  voicePitches: string[];

  ethnicOrigins: string[];
  hairColor: string | null;
  hairLength: string | null;
  eyeColor: string | null;

  dimensionTable: DimensionTable | null;
}

export interface UserNote {
  userID: UserID;
  note: string;
}
export function loadUserNote(data: Record<string, unknown>): UserNote {
  return {
    userID: expectNonemptyString(data.userID),
    note: expectString(data.note),
  };
}

export interface NameInformation {
  firstName: string;
  lastName: string;
  title: Title | null;
  surnames: string[];
}

export function loadNameInformation(
  data: Record<string, unknown>,
): NameInformation {
  return {
    firstName: expectNonemptyString(data.firstName),
    lastName: expectNonemptyString(data.lastName),
    title: expectTitleOrNull(data.title),
    surnames: expectArray(data.surnames).map((e) => expectNonemptyString(e)),
  };
}

export function loadPerson(data: Record<string, unknown>): Person {
  return {
    ...loadNameInformation(data),
    id: expectNonemptyString(data.id),
    agencyID: expectNonemptyString(data.agencyID),
    clientOf: expectArray(data.clientOf).map(expectNonemptyString),
    userNotes: loadArrayOfObjects(data.userNotes, loadUserNote),

    birthday: expectDateOrNull(data.birthday),
    gender: expectGenderOrNull(data.gender),

    address: loadObject(data.address, loadAddress),
    additionalAddresses: expectArray(data.additionalAddresses).map((e) =>
      loadObject(e, (v) => ({
        name: expectNonemptyString(v.name),
        address: loadObject(v.address, loadAddress),
      })),
    ),

    email: expectNonemptyStringOrNull(data.email),
    additionalEmails: expectArray(data.additionalEmails).map((e) =>
      expectNonemptyString(data.email),
    ),

    companyID: expectNonemptyStringOrNull(data.companyID),
    professions: expectArray(data.professions).map((e) => expectProfession(e)),

    socialMedia: loadObject(data.socialMedia, loadPersonSocialMedia),

    phone: loadObjectOrNull(data.phone, expectPhone),
    additionalPhones: expectArray(data.additionalPhones).map((p) =>
      loadObject(p, expectPhone),
    ),

    stageName: loadObjectOrNull(data.stageName, loadNameInformation),

    birthName: loadObjectOrNull(data.birthName, loadNameInformation),

    placeOfBirth: expectNonemptyStringOrNull(data.placeOfBirth),
    countryOfBirth: expectNonemptyStringOrNull(data.countryOfBirth),
    citizenships: expectArray(data.citizenships).map((e) =>
      expectNonemptyString(e),
    ),
    maritalStatus: expectEnumOrNull(data.maritalStatus, maritalStatusTypes),
    identityCardNumber: expectNonemptyStringOrNull(data.identityCardNumber),

    relatives: expectArray(data.relatives).map((e) =>
      loadObject(e, (v) => ({
        relation: expectEnum(v.relation, relationTypes),
        personID: expectNonemptyString(v.personID),
      })),
    ),
    religion: expectNonemptyStringOrNull(data.religion),

    bankAccount: loadObjectOrNull(data.bankAccount, loadBankAccount),

    healthInsurance: loadObjectOrNull(
      data.healthInsurance,
      loadHealthInsurance,
    ),

    smallBusiness: loadObjectOrNull(data.smallBusiness, loadSmallBusiness),

    socialInsuranceNumber: expectNonemptyStringOrNull(
      data.socialInsuranceNumber,
    ),
    pensionInsuranceNumber: expectNonemptyStringOrNull(
      data.pensionInsuranceNumber,
    ),
    taxIdentificationNumber: expectNonemptyStringOrNull(
      data.taxIdentificationNumber,
    ),
    taxClass: expectNonemptyStringOrNull(data.taxClass),
    taxOffice: expectNonemptyStringOrNull(data.taxOffice),
    childrenTaxIDs: expectArray(data.childrenTaxIDs).map((e) =>
      expectNonemptyString(e),
    ),
    valueAddedTaxNumber: expectNonemptyStringOrNull(data.valueAddedTaxNumber),

    artistSocialFund: expectNonemptyStringOrNull(data.artistSocialFund),
    pensionFundNumber: expectNonemptyStringOrNull(data.pensionFundNumber),
    pensionFundPercentage: loadObjectOrNull(
      data.pensionFundPercentage,
      loadPensionFundPercentage,
    ),
    memberOfGVL: expectBoolean(data.memberOfGVL),

    bahnCard: loadObjectOrNull(data.bahnCard, loadBahnCard),

    bonusMiles: expectArray(data.bonusMiles).map((e) =>
      loadObject(e, loadBonusMile),
    ),

    nourishments: expectArray(data.nourishments).map((e) =>
      expectNonemptyString(e),
    ),
    allergies: expectArray(data.allergies).map((e) => expectNonemptyString(e)),

    vehicleLicenses: expectArray(data.vehicleLicenses).map((e) =>
      loadObject(e, loadVehicleLicense),
    ),

    education: expectArray(data.education).map((e) =>
      loadObject(e, loadEducationDegree),
    ),
    languages: expectArray(data.languages).map((e) => expectNonemptyString(e)),
    dialects: expectArray(data.dialects).map((e) => expectNonemptyString(e)),
    sports: expectArray(data.sports).map((e) => expectNonemptyString(e)),
    dances: expectArray(data.dances).map((e) => expectNonemptyString(e)),
    instruments: expectArray(data.instruments).map((e) =>
      expectNonemptyString(e),
    ),
    vocalStyles: expectArray(data.vocalStyles).map((e) =>
      expectNonemptyString(e),
    ),
    voicePitches: expectArray(data.voicePitches).map((e) =>
      expectNonemptyString(e),
    ),

    ethnicOrigins: expectArray(data.ethnicOrigins).map((e) =>
      expectNonemptyString(e),
    ),
    hairColor: expectNonemptyStringOrNull(data.hairColor),
    hairLength: expectNonemptyStringOrNull(data.hairLength),
    eyeColor: expectNonemptyStringOrNull(data.eyeColor),

    dimensionTable: loadObjectOrNull(data.dimensionTable, loadDimensionTable),
  };
}

export const pensionFundPercentageTypes = [4, 7] as const;

export type PensionFundPercentage = (typeof pensionFundPercentageTypes)[number];

function isPensionFundPercentage(
  value: unknown,
): value is PensionFundPercentage {
  return pensionFundPercentageTypes.includes(value as PensionFundPercentage);
}

function loadPensionFundPercentage(rawValue: unknown): PensionFundPercentage {
  const val = expectNumber(rawValue);
  if (!isPensionFundPercentage(val)) {
    throw new Error(`Invalid pensionFundPercentage: ${val}`);
  }
  return val;
}

export type PersonSearchable = AlgoliaSearchable<
  Pick<Person, "firstName" | "lastName" | "clientOf"> & { name: string }
>;

export function loadPersonSearchable(
  data: Record<string, unknown>,
): PersonSearchable {
  const firstName = expectNonemptyString(data.firstName);
  const lastName = expectNonemptyString(data.lastName);

  return {
    objectID: expectNonemptyString(data.objectID),
    firstName,
    lastName,
    clientOf: expectArray(data.clientOf).map(expectNonemptyString),
    name: displayName(firstName, lastName),
  };
}
