import { initializeApp } from "firebase/app";

const DOMAIN = "agency-manager-66313";

export const firebaseConfig = {
  apiKey: "AIzaSyDvB1bgvfTy-jFfENkpdOlZmN6KHoKC_7Q",
  projectId: DOMAIN,
  authDomain: `${DOMAIN}.firebaseapp.com`,
  storageBucket: `${DOMAIN}.appspot.com`,
  messagingSenderId: "742213406025",
  appId: "1:742213406025:web:73bb3b41aed43298ae9e98",
};

export const initializeFirebase = () => initializeApp(firebaseConfig);
