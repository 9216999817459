import { useSearchBox, UseSearchBoxProps } from "react-instantsearch-hooks";
import React, { useEffect, useRef, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import Iconify from "../components/Iconify";

export type SearchBoxProps = UseSearchBoxProps & { placeholder: string };

export function SearchBox(props: SearchBoxProps) {
  const { query, refine } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleReset = (event: React.FormEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    setInputValue("");

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Track when the value coming from the React state changes to synchronize
  // it with InstantSearch.
  useEffect(() => {
    if (query !== inputValue) {
      refine(inputValue);
    }
  }, [inputValue, refine]);

  // Track when the InstantSearch query changes to synchronize it with
  // the React state.
  useEffect(() => {
    // Bypass the state update if the input is focused to avoid concurrent
    // updates when typing.
    if (document.activeElement !== inputRef.current && query !== inputValue) {
      setInputValue(query);
    }
  }, [query]);

  return (
    <Box className="ais-SearchBox">
      <form
        action=""
        className="ais-SearchBox-form"
        noValidate
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <TextField
          className={"ais-SearchBox-input"}
          inputRef={inputRef}
          fullWidth
          type="search"
          value={inputValue}
          placeholder={props.placeholder}
          onChange={(event) => setInputValue(event.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Box>
  );
}
