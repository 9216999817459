import { parseISO } from "date-fns";

export function expectEnum<T extends string | number>(
  data: unknown,
  options: readonly T[],
): T {
  if (!options.includes(data as T)) {
    // do not use stringify directly on options to keep `undefined`
    const optionsList = options.map((o) => JSON.stringify(o)).join(", ");
    throw new Error(
      `expected one of [${optionsList}], got ${JSON.stringify(data)}`,
    );
  }

  return data as T;
}

export function expectEnumOrNull<T extends string | number>(
  data: unknown,
  options: readonly T[],
): T | null {
  if (data === undefined || data === null || data === "") {
    return null;
  }

  return expectEnum(data, options);
}

/** @deprecated Migrate to expectEnumOrNull. */
export function expectEnumOrEmptyString<T extends string>(
  data: unknown,
  options: readonly T[],
): T | "" {
  if (data === undefined || data === null || data === "") {
    return "";
  }

  return expectEnum(data, options);
}

export function expectString(data: unknown): string {
  data = data ?? "";
  if (typeof data !== "string") {
    throw new Error(`expected string, got ${typeof data}`);
  }
  return data;
}

export function emptyStringFallback<T>(data: unknown, fallback: T): string | T {
  const src = expectString(data);

  if (src === "") {
    return fallback;
  }

  return src;
}

export function expectNonemptyString(data: unknown): string {
  const str = expectString(data);
  if (str === "") {
    throw new Error("string is empty");
  }
  return str;
}

export function expectNonemptyStringOrNull(data: unknown): string | null {
  if (data === undefined || data === null || data === "") {
    return null;
  }
  return expectNonemptyString(data);
}

export function expectBoolean(data: unknown): boolean {
  data = data ?? false;
  if (typeof data !== "boolean") {
    throw new Error(`expected boolean, got ${typeof data}`);
  }
  return data;
}

export function expectNumber(data: unknown): number {
  data = data ?? 0;
  if (typeof data !== "number") {
    throw new Error(`expected number, got ${typeof data}`);
  }
  return data;
}

export function zeroNumberFallback<T>(data: unknown, fallback: T): number | T {
  const src = expectNumber(data);

  if (src === 0) {
    return fallback;
  }

  return src;
}

export function expectNumberOrNull(data: unknown): number | null {
  if (data === undefined || data === null) {
    return null;
  }
  return expectNumber(data);
}

export function expectDate(data: unknown): Date {
  if (typeof data === "object" && data !== null) {
    const obj: { toDate?: unknown; _seconds?: unknown } = data;
    if (typeof obj.toDate === "function") {
      data = obj.toDate();
    }
    if (typeof obj._seconds === "number") {
      data = new Date(obj._seconds * 1000);
    }
  }
  if (!(data instanceof Date)) {
    throw new Error("expected date");
  }
  return data;
}

export function expectDateOrNull(data: unknown): Date | null {
  if (data === undefined || data === null) {
    return null;
  }
  return expectDate(data);
}

export function expectISODateString(data: unknown): string {
  const dateStr = expectString(data);

  if (isNaN(parseISO(dateStr).valueOf())) {
    throw new Error(`expected ISO date string, got ${dateStr}`);
  }

  return dateStr;
}

export function expectISODateStringOrNull(data: unknown): string | null {
  if (data === undefined || data === null) {
    return null;
  }
  return expectISODateString(data);
}

export function loadObject<T>(
  data: unknown,
  loader: (v: Record<string, unknown>) => T,
): T {
  if (data !== undefined && typeof data !== "object") {
    throw new Error(`expected object, got ${typeof data}`);
  }
  if (data === undefined || data === null) {
    return loader({});
  }
  return loader(data as Record<string, unknown>);
}

export function loadObjectOrNull<T>(
  data: unknown,
  loader: (v: Record<string, unknown>) => T,
): T | null {
  if (data === undefined || data === null) {
    return null;
  }
  return loadObject(data, loader);
}

export function expectObjectWithID<T>(
  data: unknown,
  loader: (v: Record<string, unknown>) => T,
): T & { id: string } {
  const o = loadObject(data, (obj) => obj);

  return { ...loader(o), id: expectNonemptyString(o.id) };
}

export function expectArray(data: unknown): unknown[] {
  if (data === undefined || data === null) {
    return [];
  }
  if (typeof data === "object") {
    const obj = data as Record<string, unknown>;
    const length = Object.keys(obj).length;
    const array: unknown[] = new Array(length);
    for (let i = 0; i < length; i++) {
      const v = obj[i];
      if (v === undefined) {
        throw new Error("expected array");
      }
      array[i] = v;
    }
    return array;
  }
  if (!(data instanceof Array)) {
    throw new Error("expected array");
  }
  return data;
}

export function loadArrayOfObjects<T>(
  data: unknown,
  loader: (v: Record<string, unknown>) => T,
): T[] {
  return expectArray(data).map((item) => loadObject(item, loader));
}

export function expectNever(data: never): never {
  throw new Error(`unreachable: ${JSON.stringify(data)}`);
}

export function expectNonNullable<T>(data: T | null | undefined): T {
  if (data === undefined || data === null) {
    throw new Error(`expected non nullable value`);
  }
  return data;
}
