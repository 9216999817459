import { expectNonemptyStringOrNull } from "../../../utils/expect";

export interface Address {
  street: string | null;
  streetNumber: string | null;
  zip: string | null;
  city: string | null;
}

export function loadAddress(data: Record<string, unknown>): Address {
  return {
    street: expectNonemptyStringOrNull(data.street),
    streetNumber: expectNonemptyStringOrNull(data.streetNumber),
    zip: expectNonemptyStringOrNull(data.zip),
    city: expectNonemptyStringOrNull(data.city),
  };
}
