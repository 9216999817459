import { expectNonemptyString } from "../../../utils/expect";

export interface BankAccount {
  ownerName: string;
  iban: string;
  bic: string;
  institutionName: string;
  country: string;
}

export function loadBankAccount(data: Record<string, unknown>): BankAccount {
  return {
    ownerName: expectNonemptyString(data.ownerName),
    iban: expectNonemptyString(data.iban),
    bic: expectNonemptyString(data.bic),
    institutionName: expectNonemptyString(data.institutionName),
    country: expectNonemptyString(data.counrty),
  };
}
