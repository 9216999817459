// @mui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { collection, query, where } from "firebase/firestore";
import { ICONS } from "./NavConfig";
import { NavSectionVertical } from "../../../components/nav-section";
import { PATH_APP } from "../../../routes/paths";
import { NavConfig } from "../../../components/nav-section/type";
import CreateClient from "../../../modules/clients/CreateClient";
import { displayName } from "../../../../lib/@types/auth";
import {
  useFirestoreQuery,
  withFirestoreCondition,
} from "../../../utils/firestore";
import { useAuth } from "../../../hooks/useAuth";
import { loadPerson, Person } from "../../../../lib/@types/person";
import { firestore } from "../../../contexts/FirebaseContext";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(() => ({}));

// ----------------------------------------------------------------------

type Props = {
  id: string;
};

export default function NavbarVerticalClients({ id }: Props) {
  const { user } = useAuth();

  const personsData = useFirestoreQuery(
    withFirestoreCondition(user, (u) =>
      query(
        collection(firestore, "persons"),
        where("agencyID", "==", u.agencyID),
        where("clientOf", "array-contains", id),
      ),
    ),
    loadPerson,
  );

  return (
    <RootStyle>
      {!personsData.loading && [
        <NavSectionVertical
          key="clientsSection"
          navConfig={navConfig(personsData.data)}
        />,
      ]}
    </RootStyle>
  );
}

const navConfig = (clients: Person[]): NavConfig => [
  {
    subheader: "klienten",
    info: <CreateClient />,
    items: clients.map((client) => ({
      id: client.id,
      title: displayName(client.firstName, client.lastName),
      path: `${PATH_APP.clients.root}/${client.id}`,
      icon: ICONS.user,
    })),
  },
];
