import { algoliaClient } from "../../../../utils/algolia";
import { InstantSearch } from "react-instantsearch-hooks";
import { SearchContainer } from "../../../search/SearchContainer";
import { CompanyHit } from "../../../search/hits/modules/CompanyHit";
import { EventHit } from "../../../search/hits/modules/EventHit";
import { PersonHit } from "../../../search/hits/modules/PersonHit";
import { ProjectHit } from "../../../search/hits/modules/ProjectHit";
import { ProjectRoleHit } from "../../../search/hits/modules/ProjectRoleHit";
import { SearchProvider } from "../../../contexts/SearchContext";

// ----------------------------------------------------------------------

const indexHits = {
  companies: CompanyHit,
  events: EventHit,
  persons: PersonHit,
  projects: ProjectHit,
  projectRoles: ProjectRoleHit,
} as const;

export default function Searchbar() {
  const client = algoliaClient();

  return (
    <SearchProvider
      indexHits={indexHits}
      showIndexCategories={true}
      searchContainerStyle={(active) => ({
        position: "relative",
        flexGrow: active ? 10 : 0,
        transition: "all 0.2s ease-in-out",
      })}
      hitsContainerStyle={{
        position: "absolute",
        top: 56,
        left: 0,
        right: 0,
        maxHeight: "90vh",
        overflow: "auto",
      }}
    >
      <InstantSearch searchClient={client} indexName="events">
        <SearchContainer placeholder={"Suche..."} />
      </InstantSearch>
    </SearchProvider>
  );
}
