export { default as UploadIllustration } from "./illustration_upload";
export { default as SeverErrorIllustration } from "./illustration_500";
export { default as PageNotFoundIllustration } from "./illustration_404";
export { default as MotivationIllustration } from "./illustration_motivation";
export { default as ComingSoonIllustration } from "./illustration_coming_soon";
export { default as MaintenanceIllustration } from "./illustration_maintenance";
export { default as OrderCompleteIllustration } from "./illustration_order_complete";
export { default as BookingIllustration } from "./illustration_booking";
export { default as CheckInIllustration } from "./illustration_checkin";
export { default as CheckOutIllustration } from "./illustration_checkout";
//
export { default as SentIcon } from "./icon_sent";
