import { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import Iconify from "../../components/Iconify";
import { DialogAnimate } from "../../components/animate";
import CreateClientForm from "./CreateClientForm";
import { useNavigate } from "react-router-dom";
import { PATH_APP } from "../../routes/paths";

export default function CreateClient() {
  const [dialogOpen, setDialogState] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <Tooltip title="Hinzufügen">
        <IconButton
          color="primary"
          size="large"
          onClick={() => setDialogState(true)}
        >
          <Iconify icon={"eva:plus-fill"} width={20} height={20} />
        </IconButton>
      </Tooltip>

      <DialogAnimate
        open={dialogOpen}
        onClose={() => setDialogState(false)}
        fullWidth
        maxWidth="xs"
      >
        {dialogOpen && (
          <CreateClientForm
            onClose={() => setDialogState(false)}
            onCreate={(id) => navigate(`${PATH_APP.clients.root}/${id}/`)}
          />
        )}
      </DialogAnimate>
    </>
  );
}
