import { AlgoliaSearchable } from "../../utils/algolia";
import {
  expectArray,
  expectDateOrNull,
  expectEnum,
  expectNonemptyString,
  expectNumberOrNull,
  expectObjectWithID,
} from "../../utils/expect";

export interface Project {
  id: string;
  agencyID: string;

  name: string;
  lastModifiedAt: Date | null;
  category: ProjectCategory | null;

  persons: { id: string; occupation: Occupation }[];
  companies: { id: string; companyOccupation: CompanyOccupation }[];

  locations: string[];
  budget: number | null;

  dateOfCreation: Date | null;

  // mediaInfo: MediaInfo[];
}

export function loadProject(data: Record<string, unknown>): Project {
  return {
    id: expectNonemptyString(data.id),
    agencyID: expectNonemptyString(data.agencyID),

    name: expectNonemptyString(data.name),
    lastModifiedAt: expectDateOrNull(data.lastModifiedAt),
    // TODO create loader
    // category: expectEnumOrNull(data.category, projectCategoryTypes),
    category: null,

    persons: expectArray(data.persons).map((e) =>
      expectObjectWithID(e, (o) => ({
        ...o,
        occupation: expectEnum(o.occupation, occupationTypes),
      })),
    ),
    companies: expectArray(data.companies).map((e) =>
      expectObjectWithID(e, (o) => ({
        ...o,
        companyOccupation: expectEnum(
          o.companyOccupation,
          companyOccupationTypes,
        ),
      })),
    ),

    locations: expectArray(data.locations).map(expectNonemptyString),
    budget: expectNumberOrNull(data.budget),

    dateOfCreation: expectDateOrNull(data.dateOfCreation),
    // mediaInfo: expectArray(data.mediaInfo).map((e) =>
    //   loadObject(e, loadMediaInfo),
    // ),
  };
}

export const occupationTypes = [
  "accountant",
  "author",
  "cast",
  "caster",
  "casterAssistant",
  "coProducer",
  "costumeDesigner",
  "costumeAssistant",
  "director",
  "directorAssistant",
  "editor", // Redakteur
  "lineProducer",
  "prAgent",
  "producer",
  "productionManager",
  "productionAssistant",
  "showRunner",
] as const;

export type Occupation = (typeof occupationTypes)[number];

export const occupationLabels: Record<Occupation, string> = {
  accountant: "Buchhalter",
  author: "Author",
  cast: "Cast",
  caster: "Caster",
  casterAssistant: "Caster (Assistenz)",
  coProducer: "Co-Produzent",
  costumeDesigner: "Kostümbildner",
  costumeAssistant: "Kostümbildner (Assistenz)",
  director: "Regisseur",
  directorAssistant: "Regisseur (Assistenz)",
  editor: "Redakteur",
  lineProducer: "Line Producer",
  prAgent: "PR-Agent",
  producer: "Produzent",
  productionManager: "Produktionsleiter",
  productionAssistant: "Produktionsleiter (Assistenz)",
  showRunner: "Showrunner",
};

export const companyOccupationTypes = [
  "productionCompany",
  "prAgency",
  "channel", // includes streaming services
  "funding",
  "distributor",
] as const;

export type CompanyOccupation = (typeof companyOccupationTypes)[number];

export const companyOccupationLabels: Record<CompanyOccupation, string> = {
  productionCompany: "Produktionsfirma",
  prAgency: "PR-Agentur",
  channel: "Sender",
  funding: "Förderung",
  distributor: "Vertrieb",
};

export type ProjectCategory =
  | ProjectCategoryAudio
  | ProjectCategoryVideo
  | ProjectCategoryPlay;

export interface ProjectCategoryAudio {
  type: "audio";
  format: AudioCategoryFormat;
}

export interface ProjectCategoryVideo {
  type: "video";
  format: VideoCategoryFormat;
}

export interface ProjectCategoryPlay {
  type: "play";
  format: PlayCategoryFormat;
}

export const projectCategoryTypes = ["audio", "video", "play"] as const;

export type ProjectCategoryTypes = (typeof projectCategoryTypes)[number];

export const projectCategoryLabels: Record<ProjectCategoryTypes, string> = {
  audio: "Audio",
  video: "Video",
  play: "Play",
};

export const audioCategoryFormatTypes = [
  "audio-drama",
  "audiobook",
  "dubbing",
  "podcast",
] as const;
export type AudioCategoryFormat = (typeof audioCategoryFormatTypes)[number];
export const audioFormatLabels: Record<AudioCategoryFormat, string> = {
  "audio-drama": "Hörspiel",
  audiobook: "Hörbuch",
  dubbing: "Synchronisation",
  podcast: "Podcast",
};

export type VideoCategoryFormat = VideoFormatSeries | VideoFormatMovie;

export const videoCategoryFormatTypes = ["series", "movie"] as const;
export type VideoCategoryFormatType = (typeof videoCategoryFormatTypes)[number];
export const videoFormatTypeLabels: Record<VideoCategoryFormatType, string> = {
  series: "Serie",
  movie: "Film",
};

export const videoFormatSeriesTypes = ["mini", "documentary"] as const;
export type VideoFormatSeriesType = (typeof videoFormatSeriesTypes)[number];
export const videoFormatSeriesLabels: Record<VideoFormatSeriesType, string> = {
  mini: "Miniserie",
  documentary: "Dokumentarserie",
};

export interface VideoFormatSeries {
  type: "series";
  format: VideoFormatSeriesType;
}

export const videoFormatMovieTypes = ["cinema", "documentary"] as const;
export type VideoFormatMovieType = (typeof videoFormatMovieTypes)[number];
export const videoFormatMovieLabels: Record<VideoFormatMovieType, string> = {
  cinema: "Kinofilm",
  documentary: "Dokumentarfilm",
};

export interface VideoFormatMovie {
  type: "movie";
  format: VideoFormatMovieType;
}

export const playCategoryFormatTypes = [
  "stage-play",
  "musical",
  "opera",
  "reading",
] as const;
export type PlayCategoryFormat = (typeof playCategoryFormatTypes)[number];
export const playFormatLabels: Record<PlayCategoryFormat, string> = {
  "stage-play": "Theaterstück",
  musical: "Musical",
  opera: "Oper",
  reading: "Lesung",
};

export type ProjectSearchable = AlgoliaSearchable<Pick<Project, "name">>;

export function loadProjectSearchable(
  data: Record<string, unknown>,
): ProjectSearchable {
  return {
    objectID: expectNonemptyString(data.objectID),
    name: expectNonemptyString(data.name),
  };
}
