import { expectDate, expectEnum, expectNever } from "../../../utils/expect";

const vehicleLicenseTypeTypes = ["land-vehicle", "water-craft"] as const;
export type VehicleLicenseType = (typeof vehicleLicenseTypeTypes)[number];

const landVehicleLicenseTypes = [
  "am",
  "a1",
  "a2",
  "a",
  "b",
  "be",
  "c1",
  "c1e",
  "c",
  "ce",
  "d1",
  "d1e",
  "d",
  "de",
  "l",
  "t",
] as const;
export type LandVehicleLicenseType = (typeof landVehicleLicenseTypes)[number];

const watercraftLicenseTypes = ["sbf-inland", "sbf-see"] as const;
export type WatercraftLicenseType = (typeof watercraftLicenseTypes)[number];

export interface LandVehicleLicense {
  type: "land-vehicle";
  class: LandVehicleLicenseType;
  expiryDate: Date;
}

export function loadVehicleLicenseLandVehicle(
  data: Record<string, unknown>,
): LandVehicleLicense {
  return {
    type: expectEnum(data.vehicleLicenseType, ["land-vehicle"]),
    class: expectEnum(data.vehicleLicenseClass, landVehicleLicenseTypes),
    expiryDate: expectDate(data.vehicleLicenseExpiryDate),
  };
}

export interface WatercraftVehicleLicense {
  type: "water-craft";
  class: WatercraftLicenseType;
  expiryDate: Date;
}

export function loadVehicleLicenseWatercraft(
  data: Record<string, unknown>,
): WatercraftVehicleLicense {
  return {
    type: expectEnum(data.vehicleLicenseType, ["water-craft"]),
    class: expectEnum(data.vehicleLicenseClass, watercraftLicenseTypes),
    expiryDate: expectDate(data.vehicleLicenseExpiryDate),
  };
}

export type VehicleLicense = LandVehicleLicense | WatercraftVehicleLicense;

export function loadVehicleLicense(
  data: Record<string, unknown>,
): VehicleLicense {
  const type = expectEnum(data.vehicleLicenseType, vehicleLicenseTypeTypes);

  switch (type) {
    case "land-vehicle":
      return loadVehicleLicenseLandVehicle(data);
    case "water-craft":
      return loadVehicleLicenseWatercraft(data);
    default:
      return expectNever(type);
  }
}
