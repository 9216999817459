import { expectEnum, expectNonemptyString } from "../../../utils/expect";

export const phoneTypes = ["mobile", "landline", "private"] as const;
export type PhoneType = (typeof phoneTypes)[number];

export interface Phone {
  type: PhoneType;
  phoneNumber: string;
}

export function expectPhone(data: Record<string, unknown>): Phone {
  return {
    type: expectEnum(data.type, phoneTypes),
    phoneNumber: expectNonemptyString(data.phoneNumber),
  };
}
