import { expectEnum, expectNever } from "../../../utils/expect";

export const professionTypes = [
  "director",
  "directorAssistant",
  "caster",
  "casterAssistant",
  "productionManager",
  "productionAssistant",
  "producer",
  "coProducer",
  "lineProducer",
  "showRunner",
  "costume",
  "costumeAssistant",
  "author",
  "prAgent",
  "accountant",
  "actor",
  "agent",
  "coach",
  "photographer",
  "other",
] as const;

export type Profession = (typeof professionTypes)[number];

export function expectProfession(data: unknown): Profession {
  return expectEnum(data, professionTypes);
}

export function professionLabel(rawProfession: string): string {
  const professions = expectEnum(rawProfession, professionTypes);

  switch (professions) {
    case "director":
      return "Regisseur/in";
    case "directorAssistant":
      return "Regisseurassistent/in";
    case "caster":
      return "Caster/in";
    case "casterAssistant":
      return "Casterassistent/in";
    case "productionManager":
      return "Produktionsmanager/in";
    case "productionAssistant":
      return "Produktionassistent/in";
    case "producer":
      return "Producer/in";
    case "coProducer":
      return "Co-Producer/in";
    case "lineProducer":
      return "Line-Producer/in";
    case "showRunner":
      return "Show Runner/in";
    case "costume":
      return "Kostüm";
    case "costumeAssistant":
      return "Kostümassistent/in";
    case "author":
      return "Autor/in";
    case "prAgent":
      return "Presseagent/in";
    case "accountant":
      return "Buchhalter/in";
    case "actor":
      return "Schauspieler/in";
    case "agent":
      return "Agent/in";
    case "coach":
      return "Coach/in";
    case "photographer":
      return "Fotograph/in";
    case "other":
      return "Sonstige";
    default:
      return expectNever(professions);
  }
}
