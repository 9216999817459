import {
  expectDateOrNull,
  expectNonemptyStringOrNull,
} from "../../../utils/expect";

export interface EducationDegree {
  degree: string | null;
  dateOfGraduation: Date | null;
  institutionID: string | null;
}

export function loadEducationDegree(
  data: Record<string, unknown>,
): EducationDegree {
  return {
    degree: expectNonemptyStringOrNull(data.degree),
    dateOfGraduation: expectDateOrNull(data.dateOfGraduation),
    institutionID: expectNonemptyStringOrNull(data.institutionID),
  };
}
