import { expectNumberOrNull } from "../../../utils/expect";

export interface DimensionTable {
  height: number | null;
  leftLeg: number | null;
  rightLeg: number | null;
}

export function loadDimensionTable(
  data: Record<string, unknown>,
): DimensionTable {
  return {
    height: expectNumberOrNull(data.height),
    leftLeg: expectNumberOrNull(data.leftLeg),
    rightLeg: expectNumberOrNull(data.rightLeg),
  };
}
