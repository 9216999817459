// routes
import Router from "./frontend/routes";
// theme
import ThemeProvider from "./frontend/theme";
// components
import Settings from "./frontend/components/settings";
import { ChartStyle } from "./frontend/components/chart";
import ScrollToTop from "./frontend/components/ScrollToTop";
import { ProgressBarStyle } from "./frontend/components/ProgressBar";
import NotistackProvider from "./frontend/components/NotistackProvider";
import ThemeColorPresets from "./frontend/components/ThemeColorPresets";
import MotionLazyContainer from "./frontend/components/animate/MotionLazyContainer";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <NotistackProvider>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <ChartStyle />
            <Settings />
            <ScrollToTop />
            <Router />
          </MotionLazyContainer>
        </NotistackProvider>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
