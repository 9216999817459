import React, { createContext, ReactNode, useContext } from "react";
import { AlgoliaIndexKey } from "../../utils/algolia";
import { PartialRecord } from "../../utils/type";

export type SearchContextType = {
  indexHits: PartialRecord<
    AlgoliaIndexKey,
    (props: { hit: any }) => JSX.Element
  >;
  onHitSelect?: (hit: Record<string, string>) => void;
  showIndexCategories: boolean;
  searchContainerStyle?: (active: boolean) => React.CSSProperties | undefined;
  hitsContainerStyle?: React.CSSProperties | undefined;
};

const SearchContext = createContext<SearchContextType | null>(null);

type SearchProviderProps = SearchContextType & {
  children: ReactNode;
};

export function SearchProvider({ children, ...props }: SearchProviderProps) {
  return (
    <SearchContext.Provider
      value={{
        ...props,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export const useSearch = (): SearchContextType => {
  const context = useContext(SearchContext);

  if (context === null) {
    throw new Error("Search context must be use inside SearchProvider");
  }

  return context;
};
