import { AlgoliaSearchable } from "../../utils/algolia";
import {
  expectArray,
  expectEnum,
  expectEnumOrNull,
  expectNever,
  expectNonemptyString,
  expectNonemptyStringOrNull,
  loadObject,
  loadObjectOrNull,
} from "../../utils/expect";

import { Address, loadAddress } from "./person-helpers/address";
import { expectPhone, Phone } from "./person-helpers/phone";

export interface Company {
  id: string;
  agencyID: string;
  name: string;

  companyType: CompanyType | null;

  address: Address;
  additionalAddresses: { name: string; address: Address }[];

  email: string | null;
  additionalEmails: string[];

  phone: Phone | null;
  additionalPhones: Phone[];

  website: string | null;
}

export const companyTypes = [
  "production",
  "distributor",
  "castingAgency",
  "prAgency",
  "channel", // includes stream service
  "theatre",
  "other",
] as const;

export type CompanyType = (typeof companyTypes)[number];

export function companyTypeLabel(rawType: string): string {
  const type = expectEnum(rawType, companyTypes);

  switch (type) {
    case "production":
      return "Produktion";
    case "distributor":
      return "Vertrieb";
    case "castingAgency":
      return "Casting Agentur";
    case "prAgency":
      return "Presseagentur";
    case "channel":
      return "Sender";
    case "theatre":
      return "Theater";
    case "other":
      return "Sonstiges";
    default:
      return expectNever(type);
  }
}

export function loadCompany(data: Record<string, unknown>): Company {
  return {
    id: expectNonemptyString(data.id),
    agencyID: expectNonemptyString(data.agencyID),
    name: expectNonemptyString(data.name),

    companyType: expectEnumOrNull(data.companyType, companyTypes),

    address: loadObject(data.address, loadAddress),
    additionalAddresses: expectArray(data.additionalAddresses).map((e) =>
      loadObject(e, (v) => ({
        name: expectNonemptyString(v.name),
        address: loadObject(v.address, loadAddress),
      })),
    ),

    email: expectNonemptyStringOrNull(data.email),
    additionalEmails: expectArray(data.additionalEmails).map((e) =>
      expectNonemptyString(data.email),
    ),

    phone: loadObjectOrNull(data.phone, expectPhone),
    additionalPhones: expectArray(data.additionalPhones).map((p) =>
      loadObject(p, expectPhone),
    ),

    website: expectNonemptyStringOrNull(data.website),
  };
}

export type CompanySearchable = AlgoliaSearchable<Pick<Company, "name">>;

export function loadCompanySearchable(
  data: Record<string, unknown>,
): CompanySearchable {
  return {
    objectID: expectNonemptyString(data.objectID),
    name: expectNonemptyString(data.name),
  };
}
