import { AnimatePresence, AnimatePresenceProps } from "framer-motion";
import * as React from "react";

// FIXME typing problems due to react v18, replace with regular usage again after fix https://github.com/DefinitelyTyped/DefinitelyTyped/pull/56210
interface WithChildren {
  children?: React.ReactNode;
}
export const AnimatePresenceWithChildren =
  AnimatePresence as React.FunctionComponent<
    AnimatePresenceProps & WithChildren
  >;
