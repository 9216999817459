// ----------------------------------------------------------------------

function path(root: string, sublink: string): string {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_APP = {
  root: "/dashboard",
  clients: {
    root: "/clients",
    details: "/clients/:clientID/:clientSegment",
  },
  projects: {
    root: "/projects",
    details: "/projects/:projectID",
  },
  "project-roles": {
    root: "/project-roles",
    details: "/project-roles/:roleID",
    create: "/project-roles/create",
  },
  user: {
    root: "/user",
    cards: "/user/cards",
    list: "/user/list",
    newUser: "/user/new",
    editById: `/user/reece-chung/edit`,
    account: "/user/account",
  },
};
