import { expectNonemptyString } from "../../utils/expect";

export interface Agency {
  id: string;
  name: string;
}

export function loadAgency(data: Record<string, unknown>): Agency {
  return {
    id: expectNonemptyString(data.id),
    name: expectNonemptyString(data.name),
  };
}
