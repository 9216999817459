import {
  expectEnum,
  expectNever,
  expectNonemptyString,
  expectNonemptyStringOrNull,
} from "../../../utils/expect";
import { NonNullableProperties } from "../../../utils/type";

const healthInsuranceTypes = ["gkv", "pkv"] as const;

export type HealthInsuranceType = (typeof healthInsuranceTypes)[number];

interface BaseHealthInsurance {
  insurer: string;
  insuranceNumber: string;
  nameOfLastStateHealthInsurance: string | null;
}

export interface HealthInsuranceFederal extends BaseHealthInsurance {
  type: "gkv";
}

export function loadHealthInsuranceFederal(
  data: Record<string, unknown>,
): HealthInsuranceFederal {
  return {
    type: expectEnum(data.type, ["gkv"]),
    insurer: expectNonemptyString(data.insurer),
    insuranceNumber: expectNonemptyString(data.insuranceNumber),
    nameOfLastStateHealthInsurance: expectNonemptyStringOrNull(
      data.nameOfLastStateHealthInsurance,
    ),
  };
}

export interface HealthInsurancePrivate
  extends NonNullableProperties<BaseHealthInsurance> {
  type: "pkv";
}

export function loadHealthInsurancePrivate(
  data: Record<string, unknown>,
): HealthInsurancePrivate {
  return {
    type: expectEnum(data.type, ["pkv"]),
    insurer: expectNonemptyString(data.insurer),
    insuranceNumber: expectNonemptyString(data.insuranceNumber),
    nameOfLastStateHealthInsurance: expectNonemptyString(
      data.nameOfLastStateHealthInsurance,
    ),
  };
}

export type HealthInsurance = HealthInsuranceFederal | HealthInsurancePrivate;

export function loadHealthInsurance(
  data: Record<string, unknown>,
): HealthInsurance {
  const type = expectEnum(data.healthInsuranceType, healthInsuranceTypes);

  switch (type) {
    case "gkv":
      return loadHealthInsuranceFederal(data);
    case "pkv":
      return loadHealthInsurancePrivate(data);
    default:
      return expectNever(type);
  }
}
