import { expectEnum, expectNever } from "../../../utils/expect";

export const maritalStatusTypes = [
  "single",
  "engaged",
  "married",
  "widowed",
] as const;

export type MaritalStatus = (typeof maritalStatusTypes)[number];

export function expectMaritalStatus(data: unknown): MaritalStatus {
  return expectEnum(data, maritalStatusTypes);
}

export function maritalStatusLabel(rawMaritalStatus: string): string {
  const maritalStatus = expectEnum(rawMaritalStatus, maritalStatusTypes);

  switch (maritalStatus) {
    case "single":
      return "ledig";
    case "engaged":
      return "verlobt";
    case "married":
      return "verheiratet";
    case "widowed":
      return "verwitwet";
    default:
      return expectNever(maritalStatus);
  }
}
