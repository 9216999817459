import {
  expectDateOrNull,
  expectNonemptyStringOrNull,
} from "../../../utils/expect";

export interface BonusMile {
  id: string | null;
  airline: string | null;
  category: string | null;
  dateOfExpiry: Date | null;
}

export function loadBonusMile(data: Record<string, unknown>): BonusMile {
  return {
    id: expectNonemptyStringOrNull(data.id),
    airline: expectNonemptyStringOrNull(data.airline),
    category: expectNonemptyStringOrNull(data.category),
    dateOfExpiry: expectDateOrNull(data.dateOfExpiry),
  };
}
