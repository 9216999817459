// utils
import { createAvatar } from "../../utils/createAvatar";
//
import Avatar, { Props as AvatarProps } from "./Avatar";
import { useAuth } from "../hooks/useAuth";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user.data?.photoURL ?? undefined}
      alt={user.data?.displayName}
      color={
        user.data?.photoURL
          ? "default"
          : createAvatar(user.data?.displayName ?? "").color
      }
      {...other}
    >
      {createAvatar(user.data?.displayName ?? "").name}
    </Avatar>
  );
}
