import _mock from "./_mock";
import { randomInArray } from "./funcs";
/* eslint-disable @typescript-eslint/naming-convention*/

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  username: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  address: _mock.address.fullAddress(index),
  phone: _mock.phoneNumber(index),
  email: _mock.email(index),
  lastActivity: _mock.time(index),
  status: randomInArray(["online", "offline", "away", "busy"]),
  position: _mock.role(index),
}));

// ----------------------------------------------------------------------

export const _notifications = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  title: [
    "Ian McKellen beginnt Drehtermin",
    "RIP Chadwick Boseman",
    "Anstehende Drehtermine",
    "Termine bestätigen",
    "Tolle Notification",
  ][index],
  description: [
    "Bei Herr der Ringe 5",
    "Wakanda forever",
    "5 Drehs beginnen morgen",
    "2 Termine warten auf Bestätigung",
    "Mit noch tollerem subtitle",
  ][index],
  avatar: [_mock.image.avatar(1), _mock.image.avatar(2), null, null, null][
    index
  ],
  type: [
    "friend_interactive",
    "friend_interactive",
    "chat_message",
    "mail",
    "order_shipped",
  ][index],
  createdAt: _mock.time(index),
  isUnRead: [true, true, false, false, false][index],
}));
