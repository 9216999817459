import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { useAuth } from "../../hooks/useAuth";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "notistack";
import Iconify from "../../components/Iconify";
import { LoadingButton } from "@mui/lab";
import { ensureData } from "../../utils/firestore";
import { firestore } from "../../contexts/FirebaseContext";

type FormOptions = {
  firstName: string;
  lastName: string;
};

export interface CreateClientFormProps {
  initialName?: string;
  onCreate: (id: string) => void;
  onClose: () => void;
}

export default function CreateClientForm({
  initialName,
  onCreate,
  onClose,
}: CreateClientFormProps) {
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  let testFunction = (value: string | undefined) => {
    if (value === undefined) {
      return false;
    }
    const regExp =
      /[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ-]/;
    for (let c of value) {
      if (!c.match(regExp)) {
        return false;
      }
    }
    return true;
  };

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Bitte einen Vornamen eintragen.")
      .strict(true)
      .test("LetterTest", "Nur erlaubte Zeichen benutzen.", testFunction),
    lastName: Yup.string()
      .required("Bitte einen Nachnamen eintragen.")
      .strict(true)
      .test("LetterTest", "Nur erlaubte Zeichen benutzen.", testFunction),
  });

  const defaultValues = {
    firstName: initialName ?? "",
    lastName: "",
  };

  const methods = useForm<FormOptions>({
    resolver: yupResolver(ValidationSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormOptions) => {
    const { agencyID, id } = ensureData(user);

    const docRef = await addDoc(collection(firestore, "persons"), {
      agencyID,
      firstName: data.firstName,
      lastName: data.lastName,
      clientOf: [id],
    });
    onClose();
    enqueueSnackbar("Klient erfolgreich angelegt.");
    onCreate(docRef.id);
  };

  return (
    <>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Klienten anlegen
          <Tooltip title="Zurücksetzen">
            <IconButton color="primary" size="large" onClick={() => reset()}>
              <Iconify icon={"mdi:reload"} width={20} height={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ pb: 5, textAlign: "center" }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ py: 2 }}>
            <RHFTextField name="firstName" label="Vorname" />
            <RHFTextField name="lastName" label="Nachname" />
          </Stack>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={() => onClose()}>
          Abbrechen
        </Button>

        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          type="submit"
          loading={isSubmitting}
        >
          Anlegen
        </LoadingButton>
      </DialogActions>
    </>
  );
}
