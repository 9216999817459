import { expectEnum, expectNever } from "../../../utils/expect";

import { Gender } from "./gender";

export const relationTypes = [
  "parent",
  "parent-in-law",
  "step-parent",
  "godparent",
  "grandparent",
  "great-grandparent",
  "child",
  "child-in-law",
  "step-child",
  "godchild",
  "grandchild",
  "great-grandchild",
  "cousin",
  "uncle-aunt",
  "fiancé-fiancée",
  "spouse",
  "friend",
] as const;

export type Relation = (typeof relationTypes)[number];

export function expectRelation(data: unknown): Relation {
  return expectEnum(data, relationTypes);
}

export function relationLabelForGender(
  rawRelation: string,
  gender: Gender,
): string {
  const relation = expectEnum(rawRelation, relationTypes);

  switch (gender) {
    case "male": {
      const maleRelations: Record<Relation, string> = {
        parent: "Vater",
        "parent-in-law": "Schwiegervater",
        "step-parent": "Stiefvater",
        godparent: "Patenonkel",
        grandparent: "Großvater",
        "great-grandparent": "Urgroßvater",
        child: "Sohn",
        "child-in-law": "Schwiegersohn",
        "step-child": "Stiefsohn",
        godchild: "Patensohn",
        grandchild: "Enkel",
        "great-grandchild": "Urenkel",
        cousin: "Cousin",
        "uncle-aunt": "Onkel",
        "fiancé-fiancée": "Verlobter",
        spouse: "Ehemann",
        friend: "Freund",
      };

      return maleRelations[relation];
    }
    case "female": {
      const femaleRelations: Record<Relation, string> = {
        parent: "Mutter",
        "parent-in-law": "Schwiegermutter",
        "step-parent": "Stiefmnutter",
        godparent: "Patentante",
        grandparent: "Großmutter",
        "great-grandparent": "Urgroßmutter",
        child: "Tochter",
        "child-in-law": "Schwiegertochter",
        "step-child": "Stieftochter",
        godchild: "Patentochter",
        grandchild: "Enkelin",
        "great-grandchild": "Urenkelin",
        cousin: "Cousine",
        "uncle-aunt": "Tante",
        "fiancé-fiancée": "Verlobte",
        spouse: "Ehefrau",
        friend: "Freundin",
      };

      return femaleRelations[relation];
    }
    case "unspecified": {
      const unspecifiedRelations: Record<Relation, string> = {
        parent: "Elternteil",
        "parent-in-law": "Schwiegerelternteil",
        "step-parent": "Stiefelternteil",
        godparent: "Pate",
        grandparent: "Großelternteil",
        "great-grandparent": "Urgroßelternteil",
        child: "kind",
        "child-in-law": "Schwiegerkind",
        "step-child": "Stiefkind",
        godchild: "Patenkind",
        grandchild: "Kindeskind",
        "great-grandchild": "Urenkel",
        cousin: "Cousin",
        "uncle-aunt": "Geschwister des Elternteils",
        "fiancé-fiancée": "Verlobte",
        spouse: "Eheperson",
        friend: "Freund",
      };

      return unspecifiedRelations[relation];
    }
    default:
      throw expectNever(gender);
  }
}
