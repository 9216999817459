import * as React from "react";
import "./index.scss";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./fire";

const app = initializeApp(firebaseConfig);

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
// contexts
import { SettingsProvider } from "./src/frontend/contexts/SettingsContext";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from "./src/frontend/contexts/FirebaseContext";

//
import App from "./src/App";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { reportWebVitals } from "./src/reportWebVitals";
import { createRoot } from "react-dom/client";
import { runAsync } from "./src/utils/concurrency";

// ----------------------------------------------------------------------

Sentry.init({
  dsn: "https://96dae8d74a664b7a9ba5d88bbf886603@o1359815.ingest.sentry.io/6647560",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.CODE_VERSION,
});

const root = createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
    </HelmetProvider>
  </AuthProvider>,
);

// Permanently not in PWA mode
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
runAsync(async () => {
  await reportWebVitals();
});
