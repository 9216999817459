import {
  DocumentReference,
  DocumentSnapshot,
  Query,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { useMemo } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";

type FirestoreLoadingState = { loading: true; data: null };

export type FirestoreDocData<T> =
  | FirestoreLoadingState
  | { loading: false; data: T };

export type FirestoreQueryData<T> =
  | FirestoreLoadingState
  | { loading: false; data: T[] };

export function withFirestoreCondition<
  T extends { id: string },
  P extends FirestoreDocData<T> | FirestoreQueryData<T>,
  R extends DocumentReference | Query,
>(
  precondition: P,
  refFn: (p: Exclude<P, FirestoreLoadingState>["data"]) => R | null,
): R | null {
  return precondition.loading ? null : refFn(precondition.data);
}

export function withCondition<
  T extends boolean,
  R extends DocumentReference | Query,
>(precondition: T, refFn: () => R | null): R | null {
  return precondition ? refFn() : null;
}

export function ensureData<
  T extends { id: string },
  P extends FirestoreDocData<T> | FirestoreQueryData<T>,
>(rawData: P): Exclude<P, FirestoreLoadingState>["data"] {
  if (rawData.loading) {
    throw new Error("unreachable");
  }
  return rawData.data;
}

export function snapToLoadable(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): Record<string, unknown> & { id: string } {
  return {
    id: snap.id,
    ...snap.data(),
  };
}

export function useFirestoreDoc<T extends { id: string }>(
  ref: DocumentReference | null,
  loader: (data: Record<string, unknown>) => T,
): FirestoreDocData<T> {
  const [value, loading, error] = useDocument(ref, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return useMemo<FirestoreDocData<T>>(() => {
    if (loading) {
      return { loading, data: null };
    }

    if (error !== undefined) {
      throw new Error(error.code);
    }

    if (value === undefined) {
      throw new Error("unreachable");
    }

    return {
      loading,
      data: loader(snapToLoadable(value)),
    };
  }, [value]);
}

export function useFirestoreQuery<T extends { id: string }>(
  query: Query | null,
  loader: (data: Record<string, unknown>) => T,
): FirestoreQueryData<T> {
  const [value, loading, error] = useCollection(query, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  return useMemo<FirestoreQueryData<T>>(() => {
    if (loading) {
      return { loading, data: null };
    }

    if (error !== undefined) {
      throw new Error(error.code);
    }

    if (value === undefined) {
      throw new Error("unreachable");
    }

    return {
      loading,
      data: value.docs.map((doc) => loader(snapToLoadable(doc))),
    };
  }, [value]);
}

export function useFirestoreMultiQuery<T extends { id: string }>(
  queries: (Query | null)[],
  loader: (data: Record<string, unknown>) => T,
): FirestoreQueryData<T> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dataSets = queries.map((query) => useFirestoreQuery(query, loader));

  return useMemo<FirestoreQueryData<T>>(() => {
    let anyLoading = false;
    const data: T[] = [];

    for (const dataSet of dataSets) {
      if (anyLoading) {
        continue;
      }

      if (dataSet.loading) {
        anyLoading = true;
        continue;
      }

      for (const t of dataSet.data) {
        if (!data.some((accItem) => accItem.id === t.id)) {
          data.push(t);
        }
      }
    }

    if (anyLoading) {
      return { loading: true, data: null };
    }

    return {
      loading: anyLoading,
      data,
    };
  }, [dataSets]);
}
