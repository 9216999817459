// routes
import { PATH_APP } from "../../../routes/paths";
// components
import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";
import { icons } from "./icons/icons";
import { NavConfig } from "../../../components/nav-section/type";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={icons[name]} sx={{ width: 1, height: 1 }} />
);

export const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
};

const navConfig: NavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "Allgemein",
    items: [
      {
        id: "dashboard",
        title: "Dashboard",
        path: PATH_APP.root,
        icon: ICONS.dashboard,
      },
      // {
      //   title: "banking",
      //   path: PATH_APP.general.banking,
      //   icon: ICONS.banking,
      // },
      // {
      //   title: "booking",
      //   path: PATH_APP.general.booking,
      //   icon: ICONS.booking,
      // },
    ],
  },

  // // MANAGEMENT
  // // ----------------------------------------------------------------------
  // {
  //   subheader: "management",
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: "user",
  //       path: PATH_APP.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: "profile", path: PATH_APP.user.profile },
  //         { title: "cards", path: PATH_APP.user.cards },
  //         { title: "list", path: PATH_APP.user.list },
  //         { title: "create", path: PATH_APP.user.newUser },
  //         { title: "edit", path: PATH_APP.user.editById },
  //         { title: "account", path: PATH_APP.user.account },
  //       ],
  //     },
  //
  //
  //   ],
  // },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: "app",
  //   items: [
  //     {
  //       title: "calendar",
  //       path: PATH_APP.calendar,
  //       icon: ICONS.calendar,
  //     },
  //   ],
  // },
];

export default navConfig;
