import { expectNonemptyStringOrNull } from "../../../utils/expect";

export interface PersonSocialMedia {
  skype: string | null;
  instagram: string | null;
}

export function loadPersonSocialMedia(
  data: Record<string, unknown>,
): PersonSocialMedia {
  return {
    skype: expectNonemptyStringOrNull(data.skype),
    instagram: expectNonemptyStringOrNull(data.instagram),
  };
}
