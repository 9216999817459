// @mui
import { styled } from "@mui/material/styles";
import { List, Box, ListSubheader } from "@mui/material";
// type
import { NavSectionProps } from "../type";
//
import { NavListRoot } from "./NavList";
import React from "react";

// ----------------------------------------------------------------------

interface WithChildren {
  children?: React.ReactNode;
}

export const ListSubheaderStyle = styled((props: WithChildren) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

export const ListSubheaderWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  ...other
}: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderWrapper sx={{ mb: 1 }}>
            <ListSubheaderStyle>{group.subheader}</ListSubheaderStyle>

            {group.info && <Box sx={{ pt: 1 }}>{group.info}</Box>}
          </ListSubheaderWrapper>

          {group.items.map((list) => (
            <NavListRoot key={`${list.id}-${list.title}`} list={list} />
          ))}
        </List>
      ))}
    </Box>
  );
}
