import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// guards
import { useAuth } from "../hooks/useAuth";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// config
import { PATH_AFTER_LOGIN } from "../../config";
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <GeneralApp />, index: true }],
    },

    {
      path: "clients",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: ":personID",
          element: <ViewClient />,
          children: [{ path: ":clientSegment", element: <ViewClient /> }],
        },
        { path: "create", element: <CreateClient /> },
      ],
    },

    {
      path: "projects",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: ":projectID",
          children: [
            { element: <ViewProject />, index: true },
            {
              path: "role",
              children: [
                {
                  path: ":roleID",
                  element: <ProjectRoleView />,
                  children: [
                    {
                      path: ":roleSegment",
                      element: <ProjectRoleView />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    {
      path: "project-roles",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: ":roleID",
          children: [{ element: <ProjectRoleView />, index: true }],
        },
        { path: "create", element: <ProjectRoleCreateForm /> },
      ],
    },

    {
      path: "user",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "cards", element: <UserCards /> },
        { path: "list", element: <UserList /> },
        { path: "new", element: <UserCreate /> },
        { path: ":name/edit", element: <UserCreate /> },
        { path: "account", element: <UserAccount /> },
      ],
    },

    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <Navigate to="/dashboard" replace />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../auth/login/Login")));
const Register = Loadable(lazy(() => import("../auth/register/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../auth/reset-password/ResetPassword")),
);
const VerifyCode = Loadable(
  lazy(() => import("../auth/verify-code/VerifyCode")),
);
const GeneralApp = Loadable(
  lazy(() => import("../modules/dashboard/Dashboard")),
);
const UserCards = Loadable(lazy(() => import("../modules/user/UserCards")));
const UserList = Loadable(lazy(() => import("../modules/user/UserList")));
const UserAccount = Loadable(lazy(() => import("../modules/user/UserAccount")));
const UserCreate = Loadable(lazy(() => import("../modules/user/UserCreate")));
const ViewClient = Loadable(
  lazy(() => import("../modules/clients/ViewClient")),
);
const CreateClient = Loadable(
  lazy(() => import("../modules/clients/CreateClient")),
);
const ViewProject = Loadable(
  lazy(() => import("../modules/projects/ViewProject")),
);
const ProjectRoleView = Loadable(
  lazy(() => import("../modules/projectRoles/ProjectRoleView")),
);
const ProjectRoleCreateForm = Loadable(
  lazy(() => import("../modules/projectRoles/ProjectRoleCreateForm")),
);
// Main
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
