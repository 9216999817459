import { Card, Typography } from "@mui/material";
import { ReactNode } from "react";

import { Index } from "react-instantsearch-hooks";
import { Hits } from "./Hits";
import { useHits } from "react-instantsearch-hooks";
import {
  algoliaIndexName,
  expectAlgoliaIndexKey,
} from "../../../utils/algolia";
import "./hits-container.scss";
import { useSearch } from "../../contexts/SearchContext";

export interface HitsContainerProps {
  children?: ReactNode;
}

export function HitsContainer({ children }: HitsContainerProps) {
  const { results } = useHits();
  const { indexHits, hitsContainerStyle } = useSearch();

  return (
    <>
      {(results?.query ?? "") !== "" && (
        <Card
          sx={{ p: 3, position: "absolute", zIndex: 1000 }}
          elevation={0}
          className={"fallback-if-empty"}
          style={hitsContainerStyle}
        >
          {Object.entries(indexHits).map(([key, hit]) => (
            <Index indexName={key} key={`index-${key}`}>
              <Hits
                hitComponent={hit as any}
                name={algoliaIndexName(expectAlgoliaIndexKey(key))}
              />
            </Index>
          ))}
          <Typography className={"fallback"} variant={"h5"}>
            Keine Ergebnisse
          </Typography>
          {children}
        </Card>
      )}
    </>
  );
}
