import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import { loadPersonSearchable } from "../../../../lib/@types/person";
import { useSearch } from "../../../contexts/SearchContext";

const CardStyle = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    zIndex: 999,
    position: "relative",
    boxShadow: theme.customShadows.z12,
  },
}));

export function PersonHit({ hit }: { hit: Record<string, string> }) {
  const personHit = loadPersonSearchable(hit);
  const { onHitSelect } = useSearch();
  return (
    <CardStyle
      sx={{ display: "flex", alignItems: "center", p: 2, borderRadius: 1 }}
      elevation={0}
      onClick={() => onHitSelect?.(hit)}
    >
      <Typography variant={"body1"}>
        {personHit.name} ({personHit.clientOf.length > 0 ? "Klient" : "Kontakt"}
        )
      </Typography>
    </CardStyle>
  );
}
