import {
  expectDateOrNull,
  expectNonemptyStringOrNull,
  expectNumberOrNull,
} from "../../../utils/expect";

export interface BahnCard {
  id: string | null;
  class: string | null;
  percent: number | null;
  dateOfExpiry: Date | null;
}

export function loadBahnCard(data: Record<string, unknown>): BahnCard {
  return {
    id: expectNonemptyStringOrNull(data.id),
    class: expectNonemptyStringOrNull(data.class),
    percent: expectNumberOrNull(data.percent),
    dateOfExpiry: expectDateOrNull(data.dateOfExpiry),
  };
}
