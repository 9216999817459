import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import { loadCompanySearchable } from "../../../../lib/@types/company";
import { useSearch } from "../../../contexts/SearchContext";

const CardStyle = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    zIndex: 999,
    position: "relative",
    boxShadow: theme.customShadows.z12,
  },
}));

export function CompanyHit({ hit }: { hit: Record<string, string> }) {
  const companyHit = loadCompanySearchable(hit);
  const { onHitSelect } = useSearch();

  return (
    <CardStyle
      sx={{ display: "flex", alignItems: "center", p: 2, borderRadius: 1 }}
      elevation={0}
      onClick={() => onHitSelect?.(hit)}
    >
      <Typography variant={"body1"}>{companyHit.name}</Typography>
    </CardStyle>
  );
}
