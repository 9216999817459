import { createContext, ReactNode, useEffect, useReducer, useRef } from "react";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  FirebaseContextType,
  loadUser,
} from "../../lib/@types/auth";
//
import { initializeFirebase } from "../../../fire";
import {
  FirestoreDocData,
  useFirestoreDoc,
  withFirestoreCondition,
} from "../utils/firestore";

// ----------------------------------------------------------------------

export const firebaseApp = initializeFirebase();

const AUTH = getAuth(firebaseApp);

export const firestore = getFirestore(firebaseApp);

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  authUser: null,
};

const Types = {
  Initial: "INITIALISE",
} as const;

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    authUser: AuthUser;
  };
};

type FirebaseActions =
  ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === "INITIALISE") {
    const { isAuthenticated, authUser } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      authUser,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [firebaseUser, dispatch] = useReducer(reducer, initialState);

  const presence = useRef<FirestoreDocData<{ id: string }>>({
    loading: true,
    data: null,
  });

  const user = useFirestoreDoc(
    withFirestoreCondition(presence.current, (p) =>
      doc(collection(firestore, "users"), p.id),
    ),
    loadUser,
  );

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (authUser) => {
        if (authUser !== null) {
          presence.current = { loading: false, data: { id: authUser.uid } };

          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, authUser },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, authUser: null },
          });
        }
      }),
    [dispatch],
  );

  const login = (email: string, password: string) =>
    signInWithEmailAndPassword(AUTH, email, password);

  const register = (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(firestore, "users"), res.user.uid);

      await setDoc(userRef, {
        id: res.user.uid,
        email,
        firstName,
        lastName,
        // TODO make dynamic, in the beginning all new users are assigned to the test agency
        agencyID: "uA8eE6aalcEpgwi1EopY",
      });
    });

  const logout = () => signOut(AUTH);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: firebaseUser.isAuthenticated,
        isInitialized: firebaseUser.isInitialized,
        method: "firebase",
        authUser: firebaseUser.authUser,
        userReady: !user.loading,
        user,
        login,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

interface IAuthError {
  code: string;
  message: string;
}

export function isAuthError(error: unknown): error is IAuthError {
  if (typeof error !== "object") {
    return false;
  }
  const authErr: Partial<IAuthError> | null = error;
  return typeof authErr?.code === "string";
}

export function errorString(error: unknown): string {
  if (isAuthError(error)) {
    switch (error.code) {
      case "auth/invalid-email":
        return "Bitte überprüfen Sie Ihre E-Mail Adresse.";
      case "auth/short-password":
      case "auth/weak-password":
        return "Bitte wählen Sie ein Passwort mit mindestens 8 Zeichen.";
      case "auth/insecure-password":
        return "Ihr Passwort ist leider unsicher. Bitte wählen Sie ein anderes Passwort.";
      case "auth/user-disabled":
        return "Ihr Account ist deaktiviert.";
      case "auth/user-not-found":
        return "Es existiert kein Nutzer mit der angegebenen E-Mail Adresse.";
      case "auth/wrong-password":
        return "Das angegebene Passwort ist leider falsch.";
      case "auth/too-many-requests":
        return "Zu viele Versuche. Bitte probieren Sie es später erneut.";
      case "auth/email-already-in-use":
        return "Es existiert bereits ein Account mit der angegeben E-Mail Adresse.";
      case "auth/expired-action-code":
      case "auth/invalid-action-code":
        return "Der verwendete Link ist leider nicht mehr gültig.";
      case "auth/network-request-failed":
      case "auth/timeout":
        return "Es konnte keine Verbindung zu unseren Servern aufgebaut werden. Bitte überprüfen Sie Ihre Internetverbindung.";
      default:
        // use generic error below
        break;
    }
  }
  return "Ein unerwarteter Fehler ist aufgetreten.";
}
