// @mui
import { Breakpoint } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Variant } from "@mui/material/styles/createTypography";

// hooks
import useResponsive from "../frontend/hooks/useResponsive";

import { expectNonemptyString } from "./expect";

// ----------------------------------------------------------------------

export default function GetFontValue(variant: Variant) {
  const theme = useTheme();
  const breakpoints: Breakpoint = useWidth();

  const key = theme.breakpoints.up(breakpoints === "xl" ? "lg" : breakpoints);

  const hasResponsive =
    variant === "h1" ||
    variant === "h2" ||
    variant === "h3" ||
    variant === "h4" ||
    variant === "h5" ||
    variant === "h6";

  const getFont: any =
    hasResponsive && theme.typography[variant][key] !== undefined
      ? theme.typography[variant][key]
      : theme.typography[variant];

  const fontSize = remToPx(expectNonemptyString(getFont.fontSize));
  const lineHeight = Number(theme.typography[variant].lineHeight) * fontSize;
  const { fontWeight, letterSpacing } = theme.typography[variant];

  return { fontSize, lineHeight, fontWeight, letterSpacing };
}

// ----------------------------------------------------------------------

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({
  sm,
  md,
  lg,
}: {
  sm: number;
  md: number;
  lg: number;
}) {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

function useWidth(): Breakpoint {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (() => {
    let highestMatch: Breakpoint | null = null;

    for (const key of keys) {
      if (highestMatch !== null) {
        continue;
      }

      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (useResponsive("up", key)) {
        highestMatch = key;
      }
    }

    return highestMatch ?? "xs";
  })();
}
