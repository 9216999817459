import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import { loadProjectSearchable } from "../../../../lib/@types/project";
import { useNavigate } from "react-router-dom";
import { PATH_APP } from "../../../routes/paths";

const CardStyle = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    zIndex: 999,
    position: "relative",
    boxShadow: theme.customShadows.z12,
  },
}));

export function ProjectHit({ hit }: { hit: Record<string, string> }) {
  const projectHit = loadProjectSearchable(hit);

  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(`${PATH_APP.projects.root}/${projectHit.objectID}`);
  };

  return (
    <CardStyle
      sx={{ display: "flex", alignItems: "center", p: 2, borderRadius: 1 }}
      elevation={0}
      onClick={handleClick}
    >
      <Typography variant={"body1"}>{projectHit.name}</Typography>
    </CardStyle>
  );
}
