import { ReportHandler } from "web-vitals";

export const reportWebVitals = async (
  onPerfEntry?: ReportHandler,
): Promise<void> => {
  if (onPerfEntry instanceof Function) {
    await import("web-vitals").then(
      ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      },
    );
  }
};
