import { Hit as AlgoliaHit } from "@algolia/client-search";
import { useHits, UseHitsProps } from "react-instantsearch-hooks";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useSearch } from "../../contexts/SearchContext";

export type HitsProps<THit> = React.ComponentProps<"div"> &
  UseHitsProps & {
    hitComponent: (props: { hit: THit }) => JSX.Element;
    name: string;
  };

export function Hits<THit extends AlgoliaHit<Record<string, unknown>>>({
  hitComponent: Hit,
  name,
}: HitsProps<THit>) {
  const { hits } = useHits();
  const { showIndexCategories } = useSearch();

  return (
    <>
      {hits.length !== 0 && (
        <Box className="ais-Hits" sx={{ mb: 3 }}>
          {showIndexCategories && (
            <Typography variant={"h4"} sx={{ mb: 1 }}>
              {name}
            </Typography>
          )}
          <Stack spacing={1} direction={"column"}>
            {hits.map((hit) => (
              <Hit key={hit.objectID} hit={hit as unknown as THit} />
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
}
