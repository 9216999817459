import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import {
  EventType,
  eventTypeName,
  loadEvent,
  loadEventSearchable,
} from "../../../../lib/@types/IEvent";
import Label, { LabelColor } from "../../../components/Label";
import { expectNever } from "../../../../utils/expect";
import { useNavigate } from "react-router-dom";
import { snapToLoadable } from "../../../utils/firestore";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../contexts/FirebaseContext";
import { PATH_APP } from "../../../routes/paths";

const CardStyle = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    zIndex: 999,
    position: "relative",
    boxShadow: theme.customShadows.z12,
  },
}));

const eventTypeLabelColor = (type: EventType): LabelColor => {
  switch (type) {
    case "person-private":
      return "default";
    case "person-project":
      return "primary";
    case "project":
      return "info";
    default:
      return expectNever(type);
  }
};

export function EventHit({ hit }: { hit: Record<string, string> }) {
  const eventHit = loadEventSearchable(hit);

  const navigate = useNavigate();

  const handleClick = async () => {
    const data = snapToLoadable(
      await getDoc(doc(collection(firestore, "events"), eventHit.objectID)),
    );
    const event = loadEvent(data);
    switch (event.type) {
      case "person-project":
        // TODO Lets introduce the role id to the event subtype
        navigate(`${PATH_APP.projects.root}/${event.projectID}`);
        break;
      case "person-private":
        navigate(`${PATH_APP.clients.root}/${event.personID}`);
        break;
      case "project":
        navigate(`${PATH_APP.projects.root}/${event.projectID}`);
        break;
      default:
        expectNever(event);
    }
  };
  return (
    <CardStyle
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        borderRadius: 1,
      }}
      elevation={0}
      onClick={handleClick}
    >
      <Typography variant={"body1"}>{eventHit.title}</Typography>
      <Label color={eventTypeLabelColor(eventHit.type)}>
        {eventTypeName(eventHit.type)}
      </Label>
    </CardStyle>
  );
}
