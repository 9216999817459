import { format, formatDistanceToNow, getTime } from "date-fns";
import { de } from "date-fns/locale";

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), "dd MMM yyyy p");
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: de,
  });
}
