import { expectEnum, expectEnumOrNull } from "../../../utils/expect";

export const titles = [
  "mr",
  "ms",
  "mrdr",
  "msdr",
  "mrprof",
  "msprof",
  "avoidTitle",
] as const;
export type Title = (typeof titles)[number];

const titleTexts: { [t in Title]: string } = {
  mr: "Herr",
  ms: "Frau",
  avoidTitle: "keine Angabe",
  mrdr: "Herr Dr.",
  msdr: "Frau Dr.",
  mrprof: "Herr Prof.",
  msprof: "Frau Prof.",
};

export function expectTitleOrNull(data: unknown): Title | null {
  return expectEnumOrNull(data, titles);
}

export function expectTitle(data: unknown): Title {
  return expectEnum(data, titles);
}

export function titleText(title: Title | "" | undefined): string {
  if (title === undefined || title === "" || title === "avoidTitle") {
    return "";
  }
  const text = titleTexts[title];
  // intentional double-check
  if ((text as any) === undefined) {
    throw new Error(`bad title: ${title}`);
  }
  return text;
}

const titleTextsWithoutGender: { [t in Title]: string } = {
  mr: "",
  ms: "",
  avoidTitle: "",
  mrdr: "Dr.",
  msdr: "Dr.",
  mrprof: "Prof.",
  msprof: "Prof.",
};

export function titleTextWithoutGender(title: Title | "" | undefined): string {
  if (title === undefined || title === "") {
    return "";
  }
  const text = titleTextsWithoutGender[title];
  // intentional double-check
  if ((text as any) === undefined) {
    throw new Error(`bad title: ${title}`);
  }
  return text;
}

export const titleSelectItems = Object.entries(titleTexts).map(
  ([value, text]) => ({
    value,
    text,
  }),
);
