// @mui
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { collection, doc } from "firebase/firestore";
import { loadAgency } from "../../../../lib/@types/agency";
import { firestore } from "../../../contexts/FirebaseContext";
import { useFirestoreDoc } from "../../../utils/firestore";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(() => ({}));

// ----------------------------------------------------------------------

type Props = {
  agencyID: string;
};

export default function NavbarAccountAgency({ agencyID }: Props) {
  const agencyData = useFirestoreDoc(
    doc(collection(firestore, "agencies"), agencyID),
    loadAgency,
  );

  return (
    <RootStyle>
      {!agencyData.loading && (
        <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
          {agencyData.data.name}
        </Typography>
      )}
    </RootStyle>
  );
}
