import * as ic_analytics from "./ic_analytics.svg";
import * as ic_banking from "./ic_banking.svg";
import * as ic_blog from "./ic_blog.svg";
import * as ic_booking from "./ic_booking.svg";
import * as ic_calendar from "./ic_calendar.svg";
import * as ic_cart from "./ic_cart.svg";
import * as ic_chat from "./ic_chat.svg";
import * as ic_dashboard from "./ic_dashboard.svg";
import * as ic_ecommerce from "./ic_ecommerce.svg";
import * as ic_kanban from "./ic_kanban.svg";
import * as ic_mail from "./ic_mail.svg";
import * as ic_user from "./ic_user.svg";

export const icons: Record<string, unknown> = {
  ic_analytics,
  ic_banking,
  ic_blog,
  ic_booking,
  ic_calendar,
  ic_cart,
  ic_chat,
  ic_dashboard,
  ic_ecommerce,
  ic_kanban,
  ic_mail,
  ic_user,
};
