import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch";

import { expectEnum, expectNever } from "./expect";

export function algoliaClient(): SearchClient {
  return algoliasearch("WTX126DM0U", "54e24b57bc09aa34b588d4c84913e6cf");
}

export const searchClient = algoliaClient();

export const algoliaIndexKeys = [
  "companies",
  "events",
  "persons",
  "projects",
  "projectRoles",
] as const;
export type AlgoliaIndexKey = (typeof algoliaIndexKeys)[number];

export function expectAlgoliaIndexKey(data: unknown): AlgoliaIndexKey {
  return expectEnum(data, algoliaIndexKeys);
}

export function algoliaIndexName(key: AlgoliaIndexKey): string {
  switch (key) {
    case "companies":
      return "Firmen";
    case "events":
      return "Events";
    case "persons":
      return "Personen";
    case "projects":
      return "Projekte";
    case "projectRoles":
      return "Rollen";
    default:
      return expectNever(key);
  }
}

export function algoliaIndex(
  client: SearchClient,
  key: AlgoliaIndexKey,
): SearchIndex {
  return client.initIndex(key);
}

export type AlgoliaSearchable<T> = T & {
  objectID: string;
};

export function loadAlgoliaSearchable<
  T extends { id: string },
  K extends Record<string, unknown>,
  P extends AlgoliaSearchable<K>,
>(data: T, loader: (d: Record<string, unknown>) => P): P {
  return loader({
    ...data,
    objectID: data.id,
  });
}
