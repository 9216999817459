import { useEffect, useState, ReactNode } from "react";
// @mui
import { ClickAwayListener, Box } from "@mui/material";
// utils
// components
import { useSearchBox } from "react-instantsearch-hooks";
import { SearchBox } from "./SearchBox";
import { HitsContainer } from "./hits/HitsContainer";
import React from "react";
import { useSearch } from "../contexts/SearchContext";

// ----------------------------------------------------------------------

interface SearchContainerProps {
  placeholder: string;
  customHitsContainerStyle?: (
    active: boolean,
  ) => React.CSSProperties | undefined;
  children?: ReactNode;
}

export function SearchContainer({
  placeholder,
  children,
}: SearchContainerProps) {
  const { query, clear } = useSearchBox();
  const [active, setActive] = useState(query !== "");

  const { searchContainerStyle } = useSearch();

  useEffect(() => {
    if (query !== "") {
      setActive(true);
      return;
    }
    setActive(false);
  }, [query]);

  const handleClose = () => {
    setActive(false);
    clear();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box style={searchContainerStyle?.(active) ?? {}}>
        <SearchBox placeholder={placeholder} />
        {active && <HitsContainer>{children}</HitsContainer>}
      </Box>
    </ClickAwayListener>
  );
}
