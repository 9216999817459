import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import {
  loadProjectRole,
  loadProjectRoleSearchable,
  ProjectRoleStatus,
  projectRoleStatusLabel,
} from "../../../../lib/@types/project-role";
import Label, { LabelColor } from "../../../components/Label";
import { expectNever } from "../../../../utils/expect";
import { PATH_APP } from "../../../routes/paths";
import { useNavigate } from "react-router-dom";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../contexts/FirebaseContext";
import { snapToLoadable } from "../../../utils/firestore";

const CardStyle = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    zIndex: 999,
    position: "relative",
    boxShadow: theme.customShadows.z12,
  },
}));

const roleStatusLabelColor = (status: ProjectRoleStatus): LabelColor => {
  switch (status) {
    case "requested":
      return "warning";
    case "casting":
      return "warning";
    case "active":
      return "primary";
    case "archived":
      return "default";
    case "rejected":
      return "error";
    default:
      return expectNever(status);
  }
};

export function ProjectRoleHit({ hit }: { hit: Record<string, string> }) {
  const projectRoleHit = loadProjectRoleSearchable(hit);

  const navigate = useNavigate();

  const handleClick = async () => {
    const data = snapToLoadable(
      await getDoc(
        doc(collection(firestore, "projectRoles"), projectRoleHit.objectID),
      ),
    );
    const projectRole = loadProjectRole(data);
    navigate(
      `${PATH_APP.projects.root}/${projectRole.projectID}/role/${projectRoleHit.objectID}`,
    );
  };

  return (
    <CardStyle
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        borderRadius: 1,
      }}
      elevation={0}
      onClick={handleClick}
    >
      <Typography variant={"body1"}>{projectRoleHit.name}</Typography>
      <Label color={roleStatusLabelColor(projectRoleHit.status)}>
        {projectRoleStatusLabel(projectRoleHit.status)}
      </Label>
    </CardStyle>
  );
}
